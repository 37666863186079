var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { userCompaniesRequest } from "../../request/user.request";
import { HttpApiRequest } from "../../service/network.service";
import { userStore } from "../../store/user.store";
import FlexItem, { Box } from "../common/FlexItem";
import LoadingBox from "../common/LoadingBox";
import { wrapVerified } from "../common/Verified";
import { TopBar } from "../TopBar";
var Content = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n"], ["\n    width: 100%;\n"])));
var AppScreen = React.memo(function (props) {
    var uStore = userStore();
    var loadCompany = function () {
        HttpApiRequest(userCompaniesRequest())
            .promise
            .then(function (res) {
            uStore.setCompany(res.data);
        });
    };
    React.useEffect(function () {
        if (!uStore.company) {
            loadCompany();
        }
    }, []);
    return React.createElement(React.Fragment, null,
        !uStore.company && React.createElement(LoadingBox, null),
        uStore.company && React.createElement(FlexItem, { flexDirection: "column", flex: 1, justify: "flex-start", align: "flex-start" },
            React.createElement(TopBar, null),
            React.createElement(Content, { flexDirection: "column" },
                React.createElement(Content, { flex: 1 },
                    React.createElement(Outlet, null)))));
});
export default wrapVerified(AppScreen);
var templateObject_1;
