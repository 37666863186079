var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { createCompanyPayment, getCompanyPaymentMethods, getPaymentMethods, updateCompanyPayment } from "../../request/payment.request";
import { HttpApiRequest, useHttpRequesPrepareHandler } from "../../service/network.service";
import Scrollbars from "react-custom-scrollbars-2";
import styled from "styled-components";
import { Box } from "../../component/common/FlexItem";
import { Section } from "../../component/common/Section";
import { Sortable } from "../../component/common/Sortable";
import { EditButton, ListItemBase } from "../../component/common/ListItem";
import { useTranslation } from "react-i18next";
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFormSwitch } from "@coreui/react";
import LoadingBox from "../../component/common/LoadingBox";
import { Modal, ModalBody, ModalHeader, ModalHeaderTitle } from "../../component/common/Modal";
import { PaymentMethodForm } from "./form";
var logger = require("js-logger").get("PaymentMethods");
import { sortBy } from "underscore";
var Container = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex: 1;\n    height: 100%;\n    padding: 0 0.5rem;\n"], ["\n    flex: 1;\n    height: 100%;\n    padding: 0 0.5rem;\n"])));
var AddMethod = React.memo(function (props) {
    var availableMethods = props.availableMethods, companyMethods = props.companyMethods, onAdd = props.onAdd;
    return React.createElement(CDropdown, null,
        React.createElement(CDropdownToggle, { caret: false }, "+"),
        React.createElement(CDropdownMenu, null, availableMethods.map(function (item) { return React.createElement(CDropdownItem, { key: item.id, onClick: function () { return onAdd(item); } }, item.name); })));
});
export var PaymentMethods = React.memo(function (props) {
    var _a = React.useState(undefined), editMethod = _a[0], setEditMethod = _a[1];
    var paymentMethodsState = useHttpRequesPrepareHandler([]);
    var cpmState = useHttpRequesPrepareHandler([]);
    var t = useTranslation().t;
    var loadData = function () {
        cpmState.run(getCompanyPaymentMethods());
        paymentMethodsState.run(getPaymentMethods());
    };
    React.useEffect(function () {
        loadData();
    }, []);
    var toggleActive = function () {
    };
    var getPaymentMethod = function (id) {
        //const paymentMethods = paymentMethodsState.result as PaymentMethodType[];
        return paymentMethodsState.result.find(function (pm) { return pm.id == id; });
    };
    var companyPaymentMethod = function (id) {
        return cpmState.result.find(function (item) { return item.method_id === id; });
    };
    var saveCompanyPaymentMethod = function (values) {
        //ifconst
        var fields = Object.keys(values.field).map(function (field_id) { return ({
            field_id: field_id,
            value: values.field[field_id]
        }); });
        var config = editMethod.config, data = editMethod.data;
        // console.log("[PaymentMethod]", fields);
        // return;
        HttpApiRequest(data && data.id ? updateCompanyPayment(data.id, {
            methodId: config.id,
            fields: fields,
            enabled: values.enabled
        }) : createCompanyPayment({
            methodId: config.id,
            fields: fields
        })).promise
            .then(function () {
            loadData();
        })
            .catch(function (err) {
            console.error(err);
        })
            .finally(function () {
            setEditMethod(undefined);
        });
    };
    var toggleEnabled = function (data) {
        var update = {
            fields: data.fields,
            enabled: !data.enabled
        };
        HttpApiRequest(updateCompanyPayment(data.id, update))
            .promise
            .then(function () {
            loadData();
        })
            .catch(function (err) {
            console.error(err);
        });
    };
    var loading = paymentMethodsState.inProgress || cpmState.inProgress;
    var fields = paymentMethodsState.result && sortBy(paymentMethodsState.result, "sort") || [];
    return React.createElement(Container, null,
        loading && React.createElement(LoadingBox, null),
        !loading && React.createElement(Scrollbars, { style: {
                height: "100%"
            } },
            React.createElement(Section, { title: t("page.billing.pm.title") },
                React.createElement(Sortable, { items: fields, handle: ".drag-handle", sortKey: "sort", onSort: function () { }, renderItem: function (item) {
                        //const cpm = item as CompanyPaymentMethodType;
                        var cpm = companyPaymentMethod(item.id);
                        return React.createElement(ListItemBase, { key: item.id, item: item, actions: cpm ? function (item) {
                                logger.info("Render Actions.....", item);
                                return React.createElement(React.Fragment, null,
                                    React.createElement(EditButton, { item: item, onClick: function (item) {
                                            setEditMethod({
                                                config: item,
                                                data: cpm
                                            });
                                        } }));
                            } : undefined },
                            React.createElement(Box, { align: "center", gap: "0.5rem" },
                                React.createElement(CFormSwitch, { checked: cpm && cpm.enabled, onChange: function (e) {
                                        //toggleActive(item as AreaType)
                                        if (!cpm) {
                                            setEditMethod({
                                                config: item,
                                                data: cpm
                                            });
                                        }
                                        else {
                                            toggleEnabled(cpm);
                                        }
                                    } }),
                                item.name));
                    } }))),
        editMethod !== undefined && React.createElement(Modal, { visible: true },
            React.createElement(ModalHeader, { onClose: function () {
                    setEditMethod(undefined);
                } },
                React.createElement(ModalHeaderTitle, null, t("payment.method.add.text"))),
            React.createElement(ModalBody, null,
                React.createElement(Scrollbars, null,
                    React.createElement(PaymentMethodForm, { onSave: saveCompanyPaymentMethod, onCancel: function () {
                            setEditMethod(undefined);
                        }, config: editMethod && editMethod.config, data: editMethod && editMethod.data })))));
});
var templateObject_1;
