import { HttpApiRequest } from "./network.service";
import { userStore } from "../store/user.store";
var SubscriptionService = /** @class */ (function () {
    function SubscriptionService() {
    }
    SubscriptionService.prototype.getSubscriptions = function () {
        var token = userStore.getState().token;
        return HttpApiRequest({
            method: "GET",
            params: {
                token: token
            },
            url: "".concat(PAYMENT_BASE_URL, "/subscription-billing")
        });
    };
    SubscriptionService.prototype.calculatePayment = function (sId, period) {
        var token = userStore.getState().token;
        return HttpApiRequest({
            method: "GET",
            params: {
                token: token,
                period: period
            },
            url: "".concat(PAYMENT_BASE_URL, "/subscription-billing/").concat(sId, "/payment")
        });
    };
    SubscriptionService.prototype.buySubscription = function (sId, period) {
        var token = userStore.getState().token;
        return HttpApiRequest({
            method: "POST",
            params: {
                token: token
            },
            url: "".concat(PAYMENT_BASE_URL, "/subscription-billing/").concat(sId, "/payment"),
            data: {
                period: period
            }
        });
    };
    return SubscriptionService;
}());
export { SubscriptionService };
