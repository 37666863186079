var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { CCard, CCardBody, CFormSwitch } from "@coreui/react";
import { faArrowRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as _ from "underscore";
import { sortBy } from "underscore";
import { Button } from "../../common/Button";
import { getMenuOptionsRequest } from "../../request/options.request";
import { useHttpRequestHandler } from "../../service/network.service";
import FlexItem, { Box } from "../common/FlexItem";
import FormCheckboxInput from "../common/form/FormCheckboxInput";
import FormCurrencyInput from "../common/form/FormCurrencyInput";
import { FormDropZoneOrPhoto } from "../common/form/FormDropZoneOrPhoto";
import FormEditorInput from "../common/form/FormEditorInput";
import FormSwitchInput from "../common/form/FormSwitchInput";
import FormTextInput from "../common/form/FormTextInput";
import LoadingBox from "../common/LoadingBox";
import { HorizontalDivider } from "../common/Divider";
import FormSelectInput from "../common/form/FormSelectInput";
var logger = require("js-logger").get("DishForm");
export var defaultDishProperties = [
    {
        name: "prepare_time",
        defaultValue: 0
    },
    {
        name: "alcohol",
        defaultValue: 0
    },
    {
        name: "weight",
        label: "Weight/Volume",
        placeholder: "30 ml, 40g, 1 kg",
        defaultValue: 0
    },
    {
        name: "volume",
        defaultValue: 0
    },
    {
        name: "kcal",
        defaultValue: 0
    },
    {
        name: "vat",
        defaultValue: 0
    },
    {
        name: "pos_id",
        defaultValue: ""
    },
    {
        name: "youtube",
        defaultValue: ""
    }
];
export var defaultDishLabels = [
    {
        name: "vegetarian",
        label: "Vegeterian",
        defaultValue: false
    },
    {
        name: "gluten_free",
        label: "Gluten free",
        defaultValue: false
    },
    {
        name: "spicy",
        label: "Spicy",
        defaultValue: false
    },
    {
        name: "middle_spicy",
        label: "Middle spicy",
        defaultValue: false
    },
    {
        name: "recommended",
        label: "Recommended",
        defaultValue: false
    },
    {
        name: "vegan",
        label: "Vegan",
        defaultValue: false
    },
    {
        name: "new",
        label: "New",
        defaultValue: false
    },
];
export var defaultDishAllergens = [
    {
        name: "cereals_containing_gluten",
        label: "Cereals containig gluten",
        defaultValue: false
    },
    {
        name: "crustaceans",
        label: "Crustceans",
        defaultValue: false
    },
    {
        name: "eggs",
        label: "Eggs",
        defaultValue: false
    },
    {
        name: "fish",
        label: "Fish",
        defaultValue: false
    },
    {
        name: "penuts",
        label: "Peanuts",
        defaultValue: false
    },
    {
        name: "soyabeans",
        label: "Soyabeans",
        defaultValue: false
    },
    {
        name: "milk",
        label: "Milk",
        defaultValue: false
    },
    {
        name: "nuts",
        label: "Nuts",
        defaultValue: false
    },
    {
        name: "celery",
        label: "Celery",
        defaultValue: false
    },
    {
        name: "mustard",
        label: "Mustard",
        defaultValue: false
    },
    {
        name: "sesame_seeds",
        label: "Sesame seeds",
        defaultValue: false
    },
    {
        name: "sulphur_dioxide_and_sulphites",
        label: "Sulphur dioxide and sulphites",
        defaultValue: false
    },
    {
        name: "lupin",
        label: "Lupin",
        defaultValue: false
    },
    {
        name: "mollusks",
        label: "Mollusks",
        defaultValue: false
    },
    {
        name: "honey",
        label: "Honey",
        defaultValue: false
    },
];
export var DishFormInputs = React.memo(function (props) {
    var control = props.control;
    var t = useTranslation().t;
    return React.createElement(React.Fragment, null,
        React.createElement(FlexItem, { flexDirection: "column", flex: 1 },
            React.createElement(FlexItem, { flexDirection: "row", gap: 10 },
                React.createElement(FlexItem, { flex: 1, flexDirection: "column" },
                    React.createElement(FormTextInput, { control: control, label: t("dish.name.input.label"), name: "name", rules: {
                            required: true
                        } })),
                React.createElement(FlexItem, { flex: 1, flexDirection: "column" },
                    React.createElement(FormCurrencyInput, { control: control, label: t("dish.price.input.label"), name: "price", rules: {
                            required: true
                        } })))),
        React.createElement(FlexItem, { flexDirection: "column", flex: 1 },
            React.createElement(FormTextInput, { control: control, label: t("dish.details.input.label"), name: "details" })),
        React.createElement(FlexItem, { flexDirection: "row" },
            React.createElement(FlexItem, { flex: 1, flexDirection: "column" },
                React.createElement(FormEditorInput, { control: control, label: t("dish.description.input.label"), name: "description" }))),
        React.createElement(Box, null,
            React.createElement(FormDropZoneOrPhoto, { control: control, name: "image" })));
});
export var DishFormLabels = React.memo(function (props) {
    var control = props.control;
    var t = useTranslation().t;
    var field = useController({
        control: control,
        name: "labels"
    }).field;
    return React.createElement("div", { style: {
            marginTop: "10px"
        } },
        React.createElement("h6", null, t("dish.labels.text")),
        defaultDishLabels.map(function (label, index) {
            //logger.info("Field", field, "index", index)
            var prop = field;
            return React.createElement("div", { key: label.name, style: {
                    display: "inline-block",
                    width: "48%",
                    marginRight: index % 2 == 0 ? "4%" : 0
                } },
                React.createElement(FormCheckboxInput, { key: label.name, name: "labels.".concat(label.name), label: t("dish.label.".concat(label.name, ".label")), control: control }));
        }));
});
export var DishFormAllergens = React.memo(function (props) {
    var control = props.control;
    var field = useController({
        control: control,
        name: "allergens"
    }).field;
    var t = useTranslation().t;
    return React.createElement("div", { style: {
            marginTop: "10px"
        } },
        React.createElement("h6", null, t("dish.allergens.text")),
        defaultDishAllergens.map(function (allergen, index) {
            return React.createElement("div", { key: allergen.name, style: {
                    display: "inline-block",
                    width: "48%",
                    marginRight: index % 2 == 0 ? "4%" : 0
                } },
                React.createElement(FormCheckboxInput, { name: "allergens.".concat(allergen.name), label: t("dish.allergen.".concat(allergen.name, ".label")), control: control }));
        }));
});
export var DishFormProperties = React.memo(function (props) {
    var control = props.control;
    var t = useTranslation().t;
    var field = useController({
        control: control,
        name: "properties"
    }).field;
    // logger.info("Props", props);
    // logger.info("Peperties", properties);
    return React.createElement("div", { style: {
            marginTop: "10px"
        } }, defaultDishProperties.map(function (prop, index) {
        //logger.info("Field", field, "index", index)
        //const prop = field as Record<string, any>;
        return React.createElement("div", { key: prop.name, style: {
                display: "inline-block",
                width: "48%",
                marginRight: index % 2 == 0 ? "4%" : 0,
                marginTop: "10px"
            } },
            React.createElement(FormTextInput, { name: "properties.".concat(prop.name), label: t("dish.property.".concat(prop.name, ".label")), control: control, placeholder: prop.placeholder, rules: {
                    required: prop.required === true
                } }));
    }));
});
var OptionContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: #fff;\n    padding: 5px 0;\n\n"], ["\n    background-color: #fff;\n    padding: 5px 0;\n\n"])));
var OptionItem = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 5px 0;\n"], ["\n    margin: 5px 0;\n"])));
var StyledFormSwitchInput = styled(FormSwitchInput)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["    \n    color: ", ";\n    font-weight: ", ";\n"], ["    \n    color: ", ";\n    font-weight: ", ";\n"])), function (props) { return props.diff ? "#2b4beb" : ""; }, function (props) { return props.diff ? 500 : "inherit"; });
var StyledFormTextInput = styled(FormTextInput)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["    \n    color: ", ";\n    font-weight: ", ";\n"], ["    \n    color: ", ";\n    font-weight: ", ";\n"])), function (props) { return props.diff ? "#2b4beb" : ""; }, function (props) { return props.diff ? 500 : "inherit"; });
var StyledFormCurrencyInput = styled(FormCurrencyInput)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    color: ", ";\n    font-weight: ", ";\n"], ["\n    color: ", ";\n    font-weight: ", ";\n"])), function (props) { return props.diff ? "#2b4beb" : ""; }, function (props) { return props.diff ? 500 : "inherit"; });
var StyledFormCheckboxInput = styled(FormCheckboxInput)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    color: ", ";\n    font-weight: ", ";\n"], ["\n    color: ", ";\n    font-weight: ", ";\n"])), function (props) { return props.diff ? "#2b4beb" : ""; }, function (props) { return props.diff ? 500 : "inherit"; });
var OptionName = styled(Box)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    margin-bottom: 0.5rem\n"], ["\n    margin-bottom: 0.5rem\n"])));
var isDiff = function (origin, override) {
    if (override == undefined || origin == undefined) {
        return false;
    }
    return origin != override;
};
var isDefaultDiff = function (origin, override) {
    return override == true && !origin;
};
export var DishFormOption = React.memo(function (props) {
    var option = props.option, control = props.control, index = props.index, _a = props.options, options = _a === void 0 ? [] : _a, resetOption = props.resetOption;
    var t = useTranslation().t;
    var _b = React.useState(false), hasDiff = _b[0], setHasDiff = _b[1];
    var _c = useFormContext(), getValues = _c.getValues, setValue = _c.setValue;
    var itemEnabled = option.items.map(function (item) { return useWatch({
        control: control,
        name: "optionItem.".concat(item.id, ".enabled")
    }); });
    var formOptionItemValues = useWatch({
        control: control,
        name: "optionItem"
    });
    //logger.info("FormOPtionItemValues", formOptionItemValues);
    var enabled = itemEnabled.includes(true);
    var enableOptionItems = function (e) {
        //logger.info("enableOptionItems")        
        var defaultValue = e.target.checked;
        option.items.forEach(function (item) {
            var name = "optionItem.".concat(item.id, ".enabled");
            //logger.info("name", name, "defaultValue", defaultValue);
            setValue(name, defaultValue, {
                shouldDirty: true,
                shouldTouch: true
            });
        });
        //setValue(`option.${option.id}.enabled`, defaultValue);
    };
    var setItemAsDefault = function (id) { return function (e) {
        var target = e.target;
        if (target.checked) {
            option.items.forEach(function (item) {
                var name = "optionItem.".concat(item.id, ".default");
                var isDefault = item.id == id;
                //logger.info("name", name, "defaultValue", defaultValue);                
                setValue(name, isDefault, {
                    shouldDirty: true,
                    shouldTouch: true
                });
            });
        }
        else {
            var name_1 = "optionItem.".concat(id, ".default");
            setTimeout(function () {
                setValue(name_1, true);
            }, 0);
        }
    }; };
    var checkHasDiff = function () {
        var diffs = [];
        option.items.forEach(function (item) {
            //const exist = options.find((op: any) => op.option_item_id == item.id) || { data: {} };
            var posIdDiff = isDiff(item.posId, formOptionItemValues[item.id].posId);
            var priceIdDiff = isDiff(item.price, formOptionItemValues[item.id].price);
            var defaultDiff = isDefaultDiff(item.default, formOptionItemValues[item.id].default);
            if (posIdDiff || priceIdDiff || defaultDiff) {
                diffs.push(true);
            }
        });
        setHasDiff(diffs.includes(true));
    };
    React.useEffect(function () {
        checkHasDiff();
    }, []);
    React.useEffect(function () {
        checkHasDiff();
    }, [formOptionItemValues]);
    return React.createElement(Box, { display: "block" },
        React.createElement(OptionName, { gap: "1rem", align: "center" },
            React.createElement(CFormSwitch, { label: option.name, onChange: enableOptionItems, checked: enabled }),
            hasDiff && React.createElement(Button, { buttonType: "button", className: "btn-primary", onClick: function () { resetOption(option.id); } },
                React.createElement(FontAwesomeIcon, { icon: faArrowRotateLeft }))),
        enabled && React.createElement(CCard, null,
            React.createElement(CCardBody, null, _.sortBy(option.items, "sort").map(function (item, idx) {
                var exist = options.find(function (op) { return op.option_item_id == item.id; }) || { data: {} };
                //logger.info("Exists 1", item);
                //logger.info("Exists 2", exist);                                        
                return React.createElement(OptionItem, { display: "block", key: item.id },
                    React.createElement(Box, { gap: "1rem" },
                        React.createElement(Box, { flex: 0.3 },
                            React.createElement(StyledFormSwitchInput, { control: control, name: "optionItem.".concat(item.id, ".enabled"), label: item.name })),
                        React.createElement(Box, { flex: 1, justify: "flex-end", gap: "5px" },
                            React.createElement(Box, { display: "inline-block" },
                                React.createElement(StyledFormTextInput, { diff: isDiff(item.posId, formOptionItemValues[item.id].posId), control: control, name: "optionItem.".concat(item.id, ".posId"), placeholder: t("menu.option.posId.placeholder"), size: "sm" })),
                            React.createElement(Box, { display: "inline-block" },
                                React.createElement(StyledFormCurrencyInput, { diff: isDiff(item.price, formOptionItemValues[item.id].price), control: control, name: "optionItem.".concat(item.id, ".price"), placeholder: t("menu.option.price.placeholder"), size: "sm", rules: {
                                        required: true,
                                        minLength: 1
                                    } })),
                            React.createElement(Box, { display: "inline-block" },
                                React.createElement(StyledFormCheckboxInput, { diff: isDefaultDiff(item.default, formOptionItemValues[item.id].default), control: control, label: t("default.text"), name: "optionItem.".concat(item.id, ".default"), onChage: setItemAsDefault(item.id) })))));
            }))));
});
export var DishFormOptions = React.memo(function (props) {
    var menuId = props.menuId, categoryId = props.categoryId, control = props.control;
    var menuOptionsState = useHttpRequestHandler(getMenuOptionsRequest(menuId, categoryId));
    var _a = useFormContext(), setValue = _a.setValue, getValues = _a.getValues;
    var optonItems = {};
    var t = useTranslation().t;
    React.useEffect(function () {
        var options = getValues("options") || [];
        if (menuOptionsState.result) {
            menuOptionsState.result.forEach(function (menuOption, index) {
                menuOption.items.map(function (item) {
                    var exist = options.find(function (op) { return op.option_item_id == item.id; }) || {};
                    //logger.info("optoins", options, "menuOptionItem", item)
                    optonItems[item.id] = Object.assign({}, {
                        enabled: false,
                        posId: item.posId,
                        price: item.price,
                        option_item_id: item.id,
                        default: item.default
                    }, exist.data);
                });
            });
            setValue("optionItem", optonItems);
            logger.info("Options", optonItems);
        }
    }, [menuOptionsState.result]);
    if (menuOptionsState.inProgress) {
        return React.createElement(LoadingBox, null);
    }
    var resetOption = function (option_id) {
        var options = menuOptionsState.result;
        var option = options.find(function (option) { return option.id == option_id; });
        //const [optionItems] = getValues(["optionItem"]);
        var optionItems = {};
        logger.info("Reset options", option_id, option);
        option.items.forEach(function (optionItem) {
            optionItems[optionItem.id] = {
                enabled: optionItem.enabled,
                posId: optionItem.posId,
                price: optionItem.price,
                option_item_id: optionItem.id,
                default: optionItem.default
            };
            setValue("optionItem.".concat(optionItem.id, ".price"), optionItem.price);
            setValue("optionItem.".concat(optionItem.id, ".posId"), optionItem.posId);
            setValue("optionItem.".concat(optionItem.id, ".default"), optionItem.default);
        });
        logger.info("Reset optons", optionItems);
        //setValue("optionItem", optonItems);
    };
    return React.createElement(OptionContainer, null,
        React.createElement("h6", null, t("dish.options.text")),
        menuOptionsState.result && sortBy(menuOptionsState.result, "sort").map(function (option, index) { return React.createElement(DishFormOption, { key: option.id, control: control, option: option, index: index, options: getValues("options") || [], resetOption: resetOption }); }));
});
export var DishTags = React.memo(function (props) {
    var control = props.control, category = props.category;
    var t = useTranslation().t;
    return React.createElement(OptionContainer, null,
        React.createElement("h6", null, t("dish.tags.text")),
        React.createElement(Box, { gap: "1rem" }, category.tags.map(function (tag) { return React.createElement(FormCheckboxInput, { key: tag.id, name: "tags.".concat(tag.id), label: tag.tag, control: control }); })));
});
var VitaminsMeasure = {
    "vitamin.d": "mcg",
    "vitamin.a": "mcg",
    "vitamin.k": "mcg",
    "vitamin.folate": "mcg",
    "vitamin.folic_acid": "mcg",
    "vitamin.b12": "mcg",
    "vitamin.biotin": "mcg",
    "vitamin.selenium": "mcg",
    "vitamin.chromium": "mcg",
    "vitamin.molybdenum": "mcg",
};
export var DishNutrion = React.memo(function (props) {
    var _a = useFormContext(), watch = _a.watch, control = _a.control;
    var t = useTranslation().t;
    var enabled = watch("nutrition.enabled");
    var vitaminMeasure = watch("nutrition.vitamin.dataformat") || "grams";
    var vitaminOptionalEnabled = watch("nutrition.vitamin.optionalEnabled") || false;
    logger.info("vitaminMeasure", vitaminMeasure);
    var getVitaminLabel = React.useCallback(function (vitamin) {
        if (vitaminMeasure == "percentage") {
            return "%";
        }
        return VitaminsMeasure[vitamin] || "mg";
    }, [vitaminMeasure]);
    return React.createElement(OptionContainer, null,
        React.createElement(Box, { flexDirection: "row", align: "center", gap: "1rem", style: {
                marginTop: "2rem"
            } },
            React.createElement(FormCheckboxInput, { name: "nutrition.enabled", label: t("dish.nutrion.text"), control: control })),
        React.createElement(Box, { gap: "1rem", flexDirection: "column", display: enabled ? "flex" : "none" },
            React.createElement(Box, { flexDirection: "row", gap: "1rem" },
                React.createElement(Box, { flex: 1, flexDirection: "column" },
                    React.createElement(FormTextInput, { name: "nutrition.serving.weight", label: t("dish.nutrion.serving.size.weight.label"), control: control })),
                React.createElement(Box, { flex: 1, flexDirection: "column" },
                    React.createElement(FormSelectInput, { control: control, name: "nutrition.serving.unit", label: t("dish.vitamin.dataformat.label"), options: [
                            {
                                label: "Grams (g)",
                                value: "grams"
                            },
                            {
                                label: "Milliliters",
                                value: "mL"
                            }
                        ] }))),
            React.createElement(Box, { flexDirection: "column" },
                React.createElement(FormTextInput, { name: "nutrition.calories", label: t("dish.nutrion.calories.label"), control: control })),
            React.createElement(Box, { flexDirection: "row", gap: "1rem" },
                React.createElement(Box, { flexDirection: "column", flex: 1 },
                    React.createElement(FormTextInput, { name: "nutrition.fat.total", label: t("dish.nutrion.fat.total.label"), control: control })),
                React.createElement(Box, { flexDirection: "column", flex: 1 },
                    React.createElement(FormTextInput, { name: "nutrition.fat.saturated", label: t("dish.nutrion.fat.saturated.label"), control: control })),
                React.createElement(Box, { flexDirection: "column", flex: 1 },
                    React.createElement(FormTextInput, { name: "nutrition.fat.trans", label: t("dish.nutrion.fat.trans.label"), control: control }))),
            React.createElement(Box, { flexDirection: "row", gap: "1rem" },
                React.createElement(Box, { flexDirection: "column", flex: 1 },
                    React.createElement(FormTextInput, { name: "nutrition.fat.polyunsaturated", label: t("dish.nutrion.fat.polyunsaturated.label"), control: control })),
                React.createElement(Box, { flexDirection: "column", flex: 1 },
                    React.createElement(FormTextInput, { name: "nutrition.fat.monounsaturated", label: t("dish.nutrion.fat.monounsaturated.label"), control: control })),
                React.createElement(Box, { flexDirection: "column", flex: 1 },
                    React.createElement(FormTextInput, { name: "nutrition.cholesterol", label: t("dish.nutrion.cholesterol.label"), control: control }))),
            React.createElement(Box, { flexDirection: "row", gap: "1rem" },
                React.createElement(Box, { flexDirection: "column", flex: 1 },
                    React.createElement(FormTextInput, { name: "nutrition.sodium", label: t("dish.nutrion.sodium.label"), control: control })),
                React.createElement(Box, { flexDirection: "column", flex: 1 },
                    React.createElement(FormTextInput, { name: "nutrition.carbohydrates.total", label: t("dish.nutrion.carbohydrates.total.label"), control: control })),
                React.createElement(Box, { flexDirection: "column", flex: 1 },
                    React.createElement(FormTextInput, { name: "nutrition.fiber.dietary", label: t("dish.nutrion.fiber.dietary.label"), control: control }))),
            React.createElement(Box, { flexDirection: "row", gap: "1rem" },
                React.createElement(Box, { flexDirection: "column", flex: 1 },
                    React.createElement(FormTextInput, { name: "nutrition.sugars", label: t("dish.nutrion.sugars.label"), control: control })),
                React.createElement(Box, { flexDirection: "column", flex: 1 },
                    React.createElement(FormTextInput, { name: "nutrition.sugar.added", label: t("dish.nutrion.sugar.added.label"), control: control })),
                React.createElement(Box, { flexDirection: "column", flex: 1 },
                    React.createElement(FormTextInput, { name: "nutrition.sugar.alcohol", label: t("dish.nutrion.sugar.alcohol.label"), control: control }))),
            React.createElement(Box, { flexDirection: "row", gap: "1rem" },
                React.createElement(Box, { flexDirection: "column", flex: 1 },
                    React.createElement(FormTextInput, { name: "nutrition.protein", label: t("dish.nutrion.protein.label"), control: control })),
                React.createElement(Box, { flexDirection: "column", flex: 1 }),
                React.createElement(Box, { flexDirection: "column", flex: 1 })),
            React.createElement(HorizontalDivider, null),
            React.createElement(Box, { flexDirection: "row", gap: "1rem" },
                React.createElement(Box, { flexDirection: "column", flex: 1, gap: "1rem" },
                    React.createElement(FormSelectInput, { control: control, name: "nutrition.vitamin.dataformat", label: t("dish.vitamin.dataformat.label"), options: [
                            {
                                label: "Units of Measure",
                                value: "measure"
                            },
                            {
                                label: "Percentage",
                                value: "percentage"
                            }
                        ] })),
                React.createElement(Box, { flexDirection: "column", flex: 1, gap: "1rem" },
                    React.createElement(FormTextInput, { name: "nutrition.vitamin.calcium", label: t("dish.vitamin.calcium.label", {
                            measure: getVitaminLabel("vitamin.calcium")
                        }), control: control })),
                React.createElement(Box, { flexDirection: "column", flex: 1 },
                    React.createElement(FormTextInput, { name: "nutrition.vitamin.iron", label: t("dish.vitamin.iron.label", {
                            measure: getVitaminLabel("vitamin.iron")
                        }), control: control }))),
            React.createElement(Box, { flexDirection: "row", gap: "1rem" },
                React.createElement(Box, { flexDirection: "column", flex: 1 },
                    React.createElement(FormTextInput, { name: "nutrition.vitamin.d", label: t("dish.vitamin.d.label", {
                            measure: getVitaminLabel("vitamin.d")
                        }), control: control })),
                React.createElement(Box, { flexDirection: "column", flex: 1 },
                    React.createElement(FormTextInput, { name: "nutrition.vitamin.potassium", label: t("dish.vitamin.potassium.label", {
                            measure: getVitaminLabel("vitamin.potassium")
                        }), control: control })),
                React.createElement(Box, { flexDirection: "column", flex: 1 })),
            React.createElement(Box, { flexDirection: "row", align: "center", gap: "1rem", style: {
                    marginTop: "2rem"
                } },
                React.createElement(FormCheckboxInput, { name: "nutrition.vitamin.optionalEnabled", label: t("dish.vitamin.optional.label"), control: control })),
            React.createElement(Box, { display: vitaminOptionalEnabled ? "flex" : "none", flexDirection: "column", gap: "1rem" },
                React.createElement(HorizontalDivider, null),
                React.createElement(Box, { flexDirection: "row", gap: "1rem" },
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.a", label: t("dish.vitamin.letter.label", {
                                letter: "A",
                                measure: getVitaminLabel("vitamin.a")
                            }), control: control })),
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormSelectInput, { control: control, name: "nutrition.vitamin.as", label: "_", options: [
                                {
                                    label: "as retinol",
                                    value: "retino"
                                },
                                {
                                    label: "as beta-carotene",
                                    value: "beta-carotene"
                                }
                            ] })),
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.c", label: t("dish.vitamin.letter.label", {
                                letter: "C",
                                measure: getVitaminLabel("vitamin.c")
                            }), control: control }))),
                React.createElement(Box, { flexDirection: "row", gap: "1rem" },
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.e", label: t("dish.vitamin.letter.label", {
                                letter: "E",
                                measure: getVitaminLabel("vitamin.e")
                            }), control: control })),
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormSelectInput, { control: control, name: "nutrition.vitamin.from", label: "_", options: [
                                {
                                    label: "from RRR-alpha-tocopherol",
                                    value: "rrr-alpha-tocopherol"
                                },
                                {
                                    label: "from All-rac-alpha-tocopheryl",
                                    value: "all-rac-alpha-tocopheryl"
                                }
                            ] })),
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.k", label: t("dish.vitamin.letter.label", {
                                letter: "K",
                                measure: getVitaminLabel("vitamin.k")
                            }), control: control }))),
                React.createElement(Box, { flexDirection: "row", gap: "1rem" },
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.thiamin", label: t("dish.vitamin.thiamin.label", {
                                measure: getVitaminLabel("vitamin.thiamin")
                            }), control: control })),
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.riboflavin", label: t("dish.vitamin.riboflavin.label", {
                                measure: getVitaminLabel("vitamin.riboflavin")
                            }), control: control })),
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.niacin", label: t("dish.vitamin.niacin.label", {
                                measure: getVitaminLabel("vitamin.niacin")
                            }), control: control }))),
                React.createElement(Box, { flexDirection: "row", gap: "1rem" },
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.b6", label: t("dish.vitamin.letter.label", {
                                letter: "B6",
                                measure: getVitaminLabel("vitamin.b6")
                            }), control: control })),
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.folate", label: t("dish.vitamin.folate.label", {
                                measure: vitaminMeasure
                            }), control: control })),
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.folic_acid", label: t("dish.vitamin.folic_acid.label", {
                                measure: getVitaminLabel("vitamin.folic_acid")
                            }), control: control }))),
                React.createElement(Box, { flexDirection: "row", gap: "1rem" },
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.b12", label: t("dish.vitamin.letter.label", {
                                letter: "B12",
                                measure: getVitaminLabel("vitamin.b12")
                            }), control: control })),
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.biotin", label: t("dish.vitamin.biotin.label", {
                                measure: vitaminMeasure
                            }), control: control })),
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.pantothenic_acid", label: t("dish.vitamin.pantothenic.label", {
                                measure: getVitaminLabel("vitamin.pantothenic_acid")
                            }), control: control }))),
                React.createElement(Box, { flexDirection: "row", gap: "1rem" },
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.phosphorus", label: t("dish.vitamin.phosphorus.label", {
                                measure: getVitaminLabel("vitamin.phosphorus")
                            }), control: control })),
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.iodine", label: t("dish.vitamin.iodine.label", {
                                measure: getVitaminLabel("vitamin.iodine")
                            }), control: control })),
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.magnesium", label: t("dish.vitamin.magnesium.label", {
                                measure: getVitaminLabel("vitamin.magnesium")
                            }), control: control }))),
                React.createElement(Box, { flexDirection: "row", gap: "1rem" },
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.zinc", label: t("dish.vitamin.zinc.label", {
                                measure: getVitaminLabel("vitamin.zinc")
                            }), control: control })),
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.selenium", label: t("dish.vitamin.selenium.label", {
                                measure: getVitaminLabel("vitamin.selenium")
                            }), control: control })),
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.copper", label: t("dish.vitamin.copper.label", {
                                measure: getVitaminLabel("vitamin.copper")
                            }), control: control }))),
                React.createElement(Box, { flexDirection: "row", gap: "1rem" },
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.manganese", label: t("dish.vitamin.manganese.label", {
                                measure: getVitaminLabel("vitamin.manganese")
                            }), control: control })),
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.chromium", label: t("dish.vitamin.chromium.label", {
                                measure: getVitaminLabel("vitamin.chromium")
                            }), control: control })),
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.molybdenum", label: t("dish.vitamin.molybdenum.label", {
                                measure: getVitaminLabel("vitamin.molybdenum")
                            }), control: control }))),
                React.createElement(Box, { flexDirection: "row", gap: "1rem" },
                    React.createElement(Box, { flexDirection: "column", flex: 1 },
                        React.createElement(FormTextInput, { name: "nutrition.vitamin.chloride", label: t("dish.vitamin.chloride.label", {
                                measure: getVitaminLabel("vitamin.chloride")
                            }), control: control })),
                    React.createElement(Box, { flexDirection: "column", flex: 1 }),
                    React.createElement(Box, { flexDirection: "column", flex: 1 })))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
