import * as React from "react";
import { useController } from "react-hook-form";
import { CFormCheck } from "@coreui/react";
var FormRadioGroupInput = function (props) {
    var items = props.items, control = props.control, name = props.name, defaultValue = props.defaultValue, rules = props.rules, inline = props.inline;
    var _a = useController({
        name: name,
        control: control,
        defaultValue: defaultValue,
        rules: rules
    }).field, onChange = _a.onChange, onBlur = _a.onBlur, _b = _a.value, value = _b === void 0 ? "" : _b, ref = _a.ref;
    console.log("VALUE", value);
    return React.createElement(React.Fragment, null, items.map(function (item) {
        return React.createElement(CFormCheck, { disabled: item.disabled, type: "radio", onChange: function (e) {
                console.log(e);
                onChange(e);
            }, onBlur: onBlur, key: item.value, inline: inline, value: item.value, label: item.label, checked: item.value == value, id: "radio-".concat(item.value) });
    }));
};
export default React.memo(FormRadioGroupInput);
