export var Feature;
(function (Feature) {
    Feature["WEBSITE"] = "website";
    Feature["ONLINE_MENU"] = "online_menu";
    Feature["MULTI_LANGUAGE_MENU"] = "multilanguage_menu";
    Feature["MODE_A"] = "mode_a";
    Feature["MODE_B"] = "mode_b";
    Feature["MODE_C"] = "mode_c";
    Feature["CONTACTLESS_TABLE_ORDERING"] = "contactless_table_ordering";
    Feature["ONLINE_PAYMENTS"] = "online_payments";
    Feature["DELIVERY_AND_TAKE_AWAY"] = "delivery_and_takeaway";
})(Feature || (Feature = {}));
export var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["CASH"] = "cash";
    PaymentMethod["POS"] = "pos";
})(PaymentMethod || (PaymentMethod = {}));
export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["IN_PROGRESS"] = "in-progress";
    PaymentStatus["FAILED"] = "failed";
    PaymentStatus["REQUIRES_CAPTURE"] = "requires_capture";
    PaymentStatus["CANCELED"] = "canceled";
    PaymentStatus["COMPLETED"] = "completed";
    PaymentStatus["ACTION"] = "action";
    PaymentStatus["REFUNDED"] = "refunded";
})(PaymentStatus || (PaymentStatus = {}));
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["NEW"] = "new";
    OrderStatus["IN_PROGRESS"] = "in-progress";
    OrderStatus["READY"] = "ready";
    OrderStatus["COMPLETED"] = "completed";
})(OrderStatus || (OrderStatus = {}));
