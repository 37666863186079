var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { CloseIcon } from "../../common/CloseIcon";
import { SubscriptionService } from "../../service/subscription.service";
import { useTranslation } from "react-i18next";
import { CButton, CProgress, CProgressBar } from "@coreui/react";
var logger = require("js-logger").get("sub.payment");
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex: 1;\n    position: relative;\n    padding: 10px 0;\n"], ["\n    display: flex;\n    flex: 1;\n    position: relative;\n    padding: 10px 0;\n"])));
var CloseIconContianer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: absolute;\n    right: 5px;\n    top: 5px;\n"], ["\n    position: absolute;\n    right: 5px;\n    top: 5px;\n"])));
var PaymentFrame = styled.iframe(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex: 1\n"], ["\n    flex: 1\n"])));
export var SubscriptionPayment = React.memo(function (props) {
    var subscriptionId = props.subscriptionId, period = props.period, onClose = props.onClose, onSuccess = props.onSuccess;
    var _a = React.useState(null), paymentURL = _a[0], setPaymentURL = _a[1];
    var _b = React.useState(null), price = _b[0], setPrice = _b[1];
    var _c = React.useState(null), remainingAmount = _c[0], setRemainingAmount = _c[1];
    var _d = React.useState(null), currency = _d[0], setCurrency = _d[1];
    var _e = React.useState(false), busy = _e[0], setBusy = _e[1];
    var subService = React.useMemo(function () {
        return new SubscriptionService();
    }, []);
    var t = useTranslation().t;
    React.useEffect(function () {
        subService.calculatePayment(subscriptionId, period)
            .promise
            .then(function (res) {
            logger.info("DATA", res.data);
            var _a = res.data, price = _a.price, remainingAmount = _a.remainingAmount, currency = _a.currency;
            setPrice(price);
            setRemainingAmount(remainingAmount);
            setCurrency(currency);
        })
            .catch(function (err) {
            logger.error();
        });
    }, []);
    var buySubscription = React.useCallback(function () {
        setPrice(null);
        setBusy(true);
        subService.buySubscription(subscriptionId, period)
            .promise
            .then(function (res) {
            logger.info("DATA", res.data);
            if (res.data.paymentPageURL) {
                setPaymentURL(res.data.paymentPageURL);
            }
            else if (res.data.status == "charged") {
                onSuccess();
            }
        })
            .catch(function (err) {
            logger.error();
        })
            .finally(function () {
            setBusy(false);
        });
    }, [paymentURL, onSuccess, price, busy]);
    React.useEffect(function () {
        var handleMessage = function (evt) {
            console.log("Message", evt.data);
            onSuccess();
        };
        window.addEventListener("message", handleMessage);
        return function () {
            window.removeEventListener("message", handleMessage);
        };
    }, []);
    return React.createElement(Container, null,
        React.createElement(CloseIconContianer, null,
            React.createElement(CloseIcon, { onClick: onClose })),
        paymentURL && React.createElement(PaymentFrame, { src: paymentURL }),
        price && React.createElement(React.Fragment, null,
            React.createElement("div", { style: { display: "flex", flex: 1, justifyContent: "center", alignItems: "center", flexDirection: 'column', gap: "10px" } },
                React.createElement("h3", null, t("subscription.billing.confirmation.line1", { type: t("subscription.billing.type.".concat(period)) })),
                React.createElement("h3", null, t("subscription.billing.confirmation.line2", { price: "".concat(price, " ").concat(currency), type: t("subscription.billing.type.".concat(period)) })),
                React.createElement("h3", null, t("subscription.billing.confirmation.line3", { remainingAmount: "".concat(remainingAmount, " ").concat(currency) })),
                React.createElement("h3", null, t("subscription.billing.confirmation.line4", { amount: "".concat(price - remainingAmount, " ").concat(currency) })),
                React.createElement("div", { style: {
                        display: "flex",
                        gap: "10px"
                    } },
                    React.createElement(CButton, { disabled: (price - remainingAmount) < 0, primary: true, onClick: buySubscription }, t("subscription.billing.purchase.btn.text")),
                    React.createElement(CButton, { color: "light", onClick: onClose }, t("subscription.billing.cancel.btn.text"))))),
        busy && React.createElement("div", { style: { display: "flex", flex: 1, justifyContent: "center", alignItems: "center" } },
            React.createElement(CProgress, { style: {
                    width: "100%"
                } },
                React.createElement(CProgressBar, { variant: "striped", color: "info", animated: true, value: 100 }))));
});
var templateObject_1, templateObject_2, templateObject_3;
